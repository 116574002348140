<template>
  <div>
    <div>
      <bread-crumb
        :crumbData="['营销活动']"
        :chooseCrumbData="1"
        reloadComponent="interactiveVue"
      ></bread-crumb>

      <div style="display: flex; align-items: center; padding: 50px 20px">
        <div
          v-for="activity in activitys"
          :key="activity.id"
          @click="activityShow(activity)"
          style="
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            border: 1px solid #cccccc;
            border-radius: 20px;
            width: 200px;
            height: 270px;
            margin-left: 30px;
            cursor: pointer;
          "
        >
          <img
            style="width: 50px; height: 50px; margin-top: 30px"
            :src="activity.pic"
            alt=""
          />
          <p style="font-size: 36px; color: #333; margin: 10px 0">
            {{ activity.title }}
          </p>
          <p style="width: 158px; font-size: 14px">
            {{ activity.tip }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activitys: [
        {
          id: 1,
          chooseModule: "openAc1Module",
          pic: require("@/assets/mianAssets/拼团.png"),
          title: "拼团",
          tip: "例：多人共同拼单。拼单成功享受团购优惠。",
        },
        {
          id: 2,
          chooseModule: "openAc1Module",
          pic: require("@/assets/mianAssets/砍价.png"),
          title: "砍价",
          tip: "例：通过分享，朋友帮忙砍价。成功砍到底价即可购买。",
        },
        {
          id: 3,
          chooseModule: "openAc1Module",
          pic: require("@/assets/mianAssets/秒杀.png"),
          title: "秒杀",
          tip: "例：给商品在规定时间内做大力度的降价活动",
        },
      ],
    };
  },
  mounted() {},
  computed: {},
  methods: {
    // 选择并打开子页面
    activityShow(value) {
      this.$store.commit("chooseModule/onChoose", value);
    },
  },
};
</script>
<style scoped></style>
